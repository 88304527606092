import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { isValidEmail } from "./utils";

import emailjs from "@emailjs/browser";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp() {
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [termsAndCondition, setTermsAndCondition] = React.useState(false);

  emailjs.init("_GcoMXzeNA-VtCuYl");

  const initialForm = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",

    // Add other form fields here
  };

  const [formData, setFormData] = React.useState(initialForm);
  const [formErrors, setFormErrors] = React.useState({});

  const serviceId = "service_hv152xh";
  const templateId = "template_r979izp";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newFormErrors = {};
    if (!formData.firstName.trim()) {
      newFormErrors.firstName = "First Name is required";
    } else if (!formData.lastName.trim()) {
      newFormErrors.lastName = "Last Name is required";
    } else if (!isValidEmail(formData.email)) {
      newFormErrors.email = "Email is required";
    } else if (!formData.password.trim()) {
      newFormErrors.password = "Password is required";
    } else if (!termsAndCondition) {
      newFormErrors.agreeTerm = " You must agree to the terms & conditions.";
    }

    setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length === 0) {
      await emailjs.send(serviceId, templateId, {
        name: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
      });
      showMessage();
      handleReset();
    }
  };

  const onCheck = () => {
    setTermsAndCondition(!termsAndCondition);
  };

  const showMessage = () => {
    Swal.fire({
      text: " The form was successfully submitted! Thank you for your request.",
      icon: "success",
    });
  };

  const handleReset = () => {
    setFormData(initialForm);
    setFormErrors({});
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography textAlign={"center"} component="h1" variant="h5">
          Data Deletion Request
        </Typography>

        <Grid spacing={2} sx={{ mt: 3 }}>
          <Typography variant="body1">
            Please enter your information on this form and we will process your
            request for deleting data associated with this account. Please read
            our{" "}
            <Link href="/privacy-policy" variant="body1" underline="none">
              privacy policy
            </Link>{" "}
            to learn more about Daxir's data collection practices and how we
            handle your personal data.
          </Typography>
        </Grid>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={formData.firstName}
                onChange={handleInputChange}
                error={!!formErrors.firstName}
                helperText={formErrors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                value={formData.lastName}
                onChange={handleInputChange}
                error={!!formErrors.lastName}
                helperText={formErrors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleInputChange}
                error={!!formErrors.password}
                helperText={formErrors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={termsAndCondition}
                    color="primary"
                    onChange={onCheck}
                  />
                }
                label="Please confirm that you agree to our term & conditions."
              />

              {formErrors.agreeTerm && (
                <Typography variant="body2" color="error">
                  {formErrors.agreeTerm}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
