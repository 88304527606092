import { Routes, Route } from "react-router-dom";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import NoMatch from "./NoMatch/NoMatch";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Header from "../Component/Header/Header";

export default function Router() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
