import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import Main from "./Main";

export default function Blog() {
  const file_name = "blog-post.md";
  const [post, setPost] = React.useState("");

  React.useEffect(() => {
    import(`./${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((err) => console.log(err));
  });
  return (
    <>
      <CssBaseline />
      <Container>
        <main>
          <Grid>
            <Main title="From the firehose" posts={[post]} />
          </Grid>
        </main>
      </Container>
    </>
  );
}
